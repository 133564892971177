@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.container {
  @include p-size-tablet {
    margin-top: 96px;
  }
}

.background {
  background-color: $ds-grey-100;
}

@for $i from 1 through 4 {
  .column-#{$i} {
    @include p-size-desktop {
      display: grid;
      grid-template-columns: repeat($i, 1fr);
      gap: 16px;
    }
  }
}

.withLargeIconParagraph {
  text-align: center;
  color: $ds-grey-600;
  margin-top: 4px;
  line-height: 24px;
}

.cardContainer {
  height: 100%;
}

.topLeftIcon {
  align-items: start;
}
