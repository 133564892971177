@import '~@popsure/dirty-swan/dist/colors';
@import '~@popsure/dirty-swan/dist/grid';

.container {
  padding: 24px 8px 0 8px;
}

.markdownOverride {
  p {
    text-align: center;
    color: $ds-grey-600;
    margin-top: 4px;
  }
}
